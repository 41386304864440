.content-header {
  // @include debug-box-model;
  margin: 0;
  font-size: 96px;
  padding-top: 0;
  width: 100%;
  padding-top: 0.5em;
  @include mq(tablet_sm) {
    padding-top: 0.5em;
  }

  @include typo-headline();
  h1 {
    font-size: 1em;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    letter-spacing: normal;
    text-transform: uppercase;
    transform: translateY(0.5em);
    margin-bottom: 1em;
  }

  &--divided {
    background-color: $color__pure_white;
    padding-left: 20px;
    h1 {
      margin-bottom: 0;
    }

    @include mq(desktop_md) {
      padding-left: 60px;
    }

    @include mq(desktop_lg) {
      padding-left: 6.46vw;

      h1 {
        // background-color: red;
      }
      margin-top: $site_masthead_height;
    }

    + .content {
      padding-top: 85px;
    }
  }
}
