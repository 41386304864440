.notes {
  p {

  }
  p br {
      content: "";
      margin: 0.25em;
      display: block;
  }
}
