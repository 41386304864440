.masthead {
  width: 100%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: $site_masthead_height;
  background-color: $color__dark_grey;
  z-index: 2;


  &__last-edited {
    color: $color__pure_white;
    margin: 0;
    padding-right: $gutter__mobile;
    @include mq(tablet_sm) {
      padding-right: $gutter__tablet;
    }
    @include mq(desktop_sm) {
      padding-right: $gutter__desktop;
    }

    span {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.36px;
    }
    time {
      width: 312px;
      height: 21px;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.3px;
      text-align: right;
      color: #ffffff;
    }
  }

  @include mq(desktop_md) {
    width: calc(100% - 258px);
  }
}
