.site-nav {
  padding: 26px 35px;
  position: relative;
  height: 100%;
  overflow-y: scroll;

  &__home-link {
    display: block;
    width: rem(115px);
    height: rem(42.8px);
    // margin-bottom: rem(76.2px);

    &-logo {

    }
  }

  &__list {
    list-style: none;
    padding: rem(76.2px) 0 0 0;
    margin: 0;
    li {
      list-style: none;
    }
    &-label {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.36px;
      text-align: left;
    }
  }

  &__sublist {
    list-style: none;
    padding: 0 0 35px 0;

    li {
      list-style: none;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.3px;
      text-align: left;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__link {
    position: relative;
    background-repeat: no-repeat;
    background-size: 8px 13px;
    background-position: 0 1px;
    padding-left: 18px;
    text-decoration: none;
    &-icon {
      visibility: hidden;
      position: absolute;
      width: 1em;
      height: 1em;
      transform: translateX(-20px) translateY(5px);
    }
    &.active {
      background-image: url('../images/icon-caret.svg');
      &-icon {
        visibility: visible;
      }
    }
  }
}
