
/*============================================================================
  # Utility Classes
==============================================================================*/
/* stylelint-disable declaration-no-important */
.is-transitioning {
  display: block !important;
  visibility: visible !important;
}

.sr,
.sr-text,
.screen-reader-text {
  @include sr-only;
}

/* stylelint-enable declaration-no-important */

.headline-text,
.typo-headline {
  @include typo-headline();
  p {
    @extend .typo-headline;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.midline-text,
.typo-midline {
  @include typo-midline();
  p {
    @extend .typo-midline;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.subhead-text,
.typo-subhead {
  @include typo-subhead();
  p {
    @extend .typo-subhead;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.subhead-title-text,
.typo-subhead-title {
  @include typo-subhead-title();
  p {
    @extend .typo-subhead-title;
    font-size: 36px;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.menu-text,
.typo-menu {
  @include typo-menu();
  p {
    @extend .typo-menu;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.title-text,
.typo-title {
  @include typo-title();
  p {
    @extend .typo-title;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.label-text,
.typo-label {
  @include typo-label();
  p {
    @extend .typo-label;
    font-size: rem(36px);
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.body-text-xl,
.typo-body-xl {
  @include typo-body-xl();
  p {
    @extend .typo-body-xl;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.body-text-lrg,
.typo-body-lrg {
  @include typo-body-lrg();
  p {
    @extend .typo-body-lrg;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.body-text-md,
.typo-body-md {
  @include typo-body-md();
  p {
    @extend .typo-body-md;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.body-text,
.typo-body {
  @include typo-body();
  p {
    @extend .typo-body;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    font-size: rem(36px);
    line-height: 1.33;
  }
}


.micro-text,
.typo-micro {
  @include typo-micro();
  p {
    @extend .typo-micro;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}

.legal-text,
.typo-legal {
  @include typo-legal();
  p {
    @extend .typo-legal;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }
}


.bg-warm-white { background-color: $color__warm_white;}

.bg-gold { background-color: $color__gold;}
.bg-gold-tint-1 {background-color: $color__gold_tint_1;}
.bg-gold-tint-2 {background-color: $color__gold_tint_2;}
.bg-gold-tint-3 {background-color: $color__gold_tint_3;}

.bg-agave { background-color: $color__agave;}
.bg-agave-tint-1 {background-color: $color__agave_tint_1;}
.bg-agave-tint-2 {background-color: $color__agave_tint_2;}
.bg-agave-tint-3 {background-color: $color__agave_tint_3;}

.bg-surf { background-color: $color__surf;}
.bg-surf-tint-1 {background-color: $color__surf_tint_1;}
.bg-surf-tint-2 {background-color: $color__surf_tint_2;}
.bg-surf-tint-3 {background-color: $color__surf_tint_3;}

.bg-firesticks { background-color: $color__firesticks;}
.bg-firesticks-tint-1 {background-color: $color__firesticks_tint_1;}
.bg-firesticks-tint-2 {background-color: $color__firesticks_tint_2;}
.bg-firesticks-tint-3 {background-color: $color__firesticks_tint_3;}

.bg-moss { background-color: $color__moss;}
.bg-moss-tint-1 {background-color: $color__moss_tint_1;}
.bg-moss-tint-2 {background-color: $color__moss_tint_2;}
.bg-moss-tint-3 {background-color: $color__moss_tint_3;}

.bg-navy-teal { background-color: $color__navy-teal;}
.bg-navy-teal-tint-1 {background-color: $color__navy-teal_tint_1;}
.bg-navy-teal-tint-2 {background-color: $color__navy-teal_tint_2;}
.bg-navy-teal-tint-3 {background-color: $color__navy-teal_tint_3;}

.bg-prickly-pear { background-color: $color__prickly-pear;}
.bg-prickly-pear-tint-1 {background-color: $color__prickly-pear_tint_1;}
.bg-prickly-pear-tint-2 {background-color: $color__prickly-pear_tint_2;}
.bg-prickly-pear-tint-3 {background-color: $color__prickly-pear_tint_3;}

