.typesetting {
  border-top: 1px solid $color__agave_tint_2;
  padding-top: 50px;
  margin-top: 33px;
  margin-bottom: 33px;

  &__example {
    margin-top: 24px;
  }
}
