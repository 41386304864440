.splash {
  $self__background_color: $color__dark_grey;
  display: flex;
  flex-direction: column;
  background-color: $self__background_color;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  &__header {
    height: vh(68, 810);
    display: flex;
    align-items: center;
  }

  &__body {
    height: vh(661, 810);
    display: flex;
    align-items: center;
  }

  &__footer {
    height: vh(81, 810);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header,
  &__body,
  &__footer {
    width: 100%;
    padding-left: $gutter__mobile;
    padding-right: $gutter__mobile;
  }

  &__home {
    margin: 0;
    &-link {
      display: block;
      width: vw(115, 810);
      min-width: rem((115px * 0.75));
      max-width: rem(115px);
      height: vh(42.8, 810);
      min-height: rem((42.8px * 0.75));
      max-height: rem(42.8px);
      margin: 0;
      &-logo {
        fill: $color__pure_white;
      }
    }
  }

  &__lockup {
    object-fit: contain;
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;

    &-monogram {
      fill: $color__agave;
    }

    &-date {
      color: $color__pure_white;
      fill: $color__pure_white;
      font-size:18px;
      font-family: $sans;
      font-weight:500;
      letter-spacing:0.02em;
    }
    &-title {
      font-family: $sans;
      font-size:140px;
      font-weight:300;
      fill: $color__pure_white;
    }

    &-bg-mask {
      fill: $self__background_color
    }

  }

  &__enter-link {
    height: rem(18px);
    width: rem(30.37px);
    display: block;

    &-icon {
      fill: $color__pure_white;
      width: 100%;
      height: 100%;
    }
  }

  @include mq(tablet_sm) {
    &__header,
    &__body,
    &__footer {
      width: 100%;
      padding-left: $gutter__tablet;
      padding-right: $gutter__tablet;
    }
  }

  @include mq(desktop_sm) {
    &__header,
    &__body,
    &__footer {
      width: 100%;
      padding-left: $gutter__desktop;
      padding-right: $gutter__desktop;
    }
  }
}
