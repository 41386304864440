// sass-lint:disable no-css-comments, space-before-colon, no-trailing-zero
// ==================================================
// Hamburger Settings
// ==================================================
$hamburger_padding_x                       : 15px !default;
$hamburger_padding_y                       : 15px !default;
$hamburger_layer_width                     : 22px !default;
$hamburger_layer_height                    : 2px !default;
$hamburger_layer_spacing                   : 2px !default;
$hamburger_layer_color                     : $color__pure_white !default;
$hamburger_layer_color_active              : $color__pure_white !default;
$hamburger_layer_border_radius             : 0px !default;
$hamburger_hover_opacity                   : 1.0 !default;
$hamburger_hover_transition_duration       : 0.15s !default;
$hamburger_hover_transition_timing_function: linear !default;
$hamburger_label_color                     : rgba($color__pure_white, 1);
$hamburger_label_font_size                 : rem(14px);
$hamburger_label_font_weight               : $font__weight_medium;
// To use CSS filters as the hover effect instead of opacity,
// set $hamburger_hover-use-filter as true and
// change the value of $hamburger_hover-filter accordingly.
$hamburger_hover_use_filter                 : false !default;
$hamburger_hover_filter                     : opacity(100%) !default;
