.color-concept {
  $self_width: 300px;
  $self_height: 160px;
  display: block;
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  // max-width: $self_width;
  height: $self_height;



  &__primary {
    position: absolute;
    top: 0;
    left: 0;
    width: percentage(210px / $self_width);
    height: 100%;
  }
  &__secondary {
    position: absolute;
    top: 0;
    right: 0;

    width: percentage(90px / $self_width);
    height: percentage(114px / $self_height);
  }
  &__tertiary {
    position: absolute;
    bottom: 0;
    right: 0;
    // width: 90px;
    // height: 46px;
    width: percentage(90px / $self_width);
    height: percentage(46px / $self_height);
  }

}
