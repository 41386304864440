// Hamburger
// ==================================================

.hamburger {
  visibility: visible;
  position: absolute;
  // position: relative;
  left: 0;
  // display: inline-block;
  display: flex;
  align-items: center;
  height: 100%;
  padding: $hamburger_padding_y $hamburger_padding_x;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: $hamburger_hover_transition_duration;
  transition-timing-function: $hamburger_hover_transition_timing_function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger_hover_use_filter == true {
      filter: $hamburger_hover_filter;
    }
    @else {
      opacity: $hamburger_hover_opacity;
    }
  }
  @include mq(desktop_sm) {
    visibility: hidden;
  }
}


.hamburger-box {
  width: $hamburger_layer_width;
  height: $hamburger_layer_height * 3 + $hamburger_layer_spacing * 2;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger_layer_height / -2;

  &,
  &::after,
  &::before {
    width: $hamburger_layer_width;
    height: $hamburger_layer_height;
    background-color: $hamburger_layer_color;
    border-radius: $hamburger_layer_border_radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::after,
  &::before {
    content: '';
    display: block;
  }

  &::before {
    top: ($hamburger_layer_spacing + $hamburger_layer_height) * -1;
  }

  &::after {
    bottom: ($hamburger_layer_spacing + $hamburger_layer_height) * -1;
  }
}

.hamburger-label {
  font-weight: $hamburger_label_font_weight;
  font-size: $hamburger_label_font_size;
  color: $hamburger_label_color;
  line-height: $hamburger_layer_height * 3 + $hamburger_layer_spacing * 2;
  display: inline-block;
  position: relative;
  margin-left: 4px;
  text-transform: uppercase;
  @include sr-only;
}

.hamburger--active {
  .hamburger-inner {
    &,
    &::after,
    &::before {
      background-color: $hamburger_layer_color_active;
    }
  }

  .hamburger-label {
    color: $hamburger_layer_color_active;
  }
}
