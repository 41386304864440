.color-swatch-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 80px;

  .color-swatch {
    width: 100%;
    @include mq(mobile_lg) {
      width: percentage(1 / 2);
    }
    @include mq(tablet_md) {
      width: percentage(1 / 3);
    }
  }

}
