.color-swatch {
  margin-bottom: 40px;
  // min-width: 230px;
  // max-width: 100%;
  &__primary {
    display: flex;

    .color {
      flex-shrink: 0;
      width: 50%;
      // width: 136px;
      padding-bottom: 50%;
      // height: 136px;
    }
    .label {
      display: flex;
      flex-direction: column;
      padding-left: 14px;
      // padding-right: 30px;
    }
  }
  &__tint {
    display: flex;

    .color {
      // width: 136px;
      width: 50%;
      height: 40px;
    }
    .label {
      padding-left: 14px;
      // padding-right: 30px;
    }
  }

}
