// ==========================================================================
// Functions
// ==========================================================================
/* stylelint-disable */
/**
 * px to em conversion
 * @link http://www.pjmccormick.com/sweet-sass-function-convert-px-em
 * @requires {variable} $font__base_size
 * @param {number} $target - size to convert
 * @param {number} $context ($font__base_size) - context conversion is based on
 * @example scss
 * .foobar { padding-bottom: em(30); }
 * @returns {number}
 */

@function em($target, $context: $font__base_size) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0em;
}

/**
 * px to rem
 * @link http://www.pjmccormick.com/sweet-sass-function-convert-px-em
 * @requires {variable} $font__base_size
 * @param {number} $target - size to convert
 * @param {number} $context ($font__base_size) - context conversion is based on
 * @example scss
 * .foobar { padding-bottom: rem(30); }
 * @returns {number}
 */

@function rem($target, $context: $font__base_size) {
  @if $target == 0 {
    @return 0;
  }
  $target: strip-unit($target);
  $context: strip-unit($context);
  @return $target / $context + 0rem;
}

/**
 * em to px conversion
 * @link http://www.pjmccormick.com/sweet-sass-function-convert-px-em
 * @requires {variable} $font__base_size
 * @param {number} $target - size to convert
 * @param {number} $context ($font__base_size) - context conversion is based on
 * @example scss
 * .foobar { padding-bottom: px(30); }
 * @returns {number}
 */

@function px($target, $context: $font__base_size) {
  @if $target == 0 {
    @return 0;
  }
  @return $target * $context + 0px;
}

/**
 * Aspect Ratio
 * @param {number} $ratio-numerator - ratio numerator
 * @param {number} $ratio-denominator - ratio denominator
 * @example scss
 * .foobar { padding-bottom: aspect-ratio(16,9); } // @returns 56.25%
 * @returns {number}
 */

@function aspect-ratio($ratio-numerator, $ratio-denominator) {
  @return (($ratio-denominator / $ratio-numerator) * 100%);
}

/**
 * Aspect Ratio
 * Based on Fixed Image Dimensions
 * @param {number} $w - image width
 * @param {number} $h - image height
 * @example scss
 * .foobar { padding: 0 0 aspect-ratio-fixed(1080, 720); } // @returns 66.666666666667%
 * @returns {number}
 */

@function aspect-ratio-fixed($w, $h) {
  @return ($h / $w) * 100%;
}

/**
 * Context Calculator
 * @param {number} $target
 * @param {number} $context
 * @example scss
 * .foobar { padding-bottom: context-calc(30,15); }
 * @returns {number}
 */

@function context-calc($target, $context) {
  @return ($target / $context) * 100%;
}

// =============================================
// VW: convert pixels to viewport width units
// basically the same as calculating percentage
// ============================================
// @param {string} $target
// @param {string} $context
// @example scss
// Input:  .foo{ font-size: rem(16px); } OR .foo{ font-size: rem(16); }
// Output: .foo{ font-size: 1rem; }
// @returns {string}
// ======================================
@function vw($target, $context) {
  // convert to percent
  $target: strip-unit($target);
  $context: strip-unit($context);
	$percent: percentage($target / $context );
	// Strip '%' off the end and add vw
	@return strip-unit($percent)+vw;
}

@function vh($target, $context) {
	// convert to percent
	$percent: percentage($target / $context );
	// Strip '%' off the end and add vw
	@return strip-unit($percent)+vh;
}

/**
 * Strip Unit Values
 * @link http://hugogiraudel.com/2013/08/12/sass-functions
 * @param {string} $num
 * @example scss
 * .foobar { padding-bottom: strip-unit(30px); }
 * @returns {number}
 */

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/**
 * Z
 * @param  string name of key
 * @return string the key value or warning
 */
@function _z($key) {
  @if map-has-key($z_indexes, $key) {
    @return map-get($z_indexes, $key);
  }

  @warn 'Unknown `#{$key}` in $z_indexes.';
  @return null;
}

@function ls($psd_letter_spacing_px) {
  @return $psd_letter_spacing_px / 1000;
}

// // Need to move
// @mixin line-height( $px_value: $rem_base ) {
//   @include rems( line-height, $px_value );
// }
// Converts the PSD font and Height to a unitless CSS line-height number
@function line-height($font_px_val, $psd_lh_px_val: '') {
  @if $psd_lh_px_val== '' {
    // @warn "No line height provided assuming auto line height";
    $font_px: strip-unit($font_px_val);
    $lh_px: $font_px * 1.2;
    // line-height/font-size
    @return $lh_px / $font_px;
  } @else {
    $font_px: strip-unit($font_px_val);
    $lh_px: strip-unit($psd_lh_px_val);
    // line-height/font-size
    @return $lh_px / $font_px;
  }
}

@function lh($font_px_val, $psd_lh_px_val: '') {
  @return line-height($font_px_val, $psd_lh_px_val);
}
/* stylelint-enable */
