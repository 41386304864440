.editor {
  $self: &;

  h1 {
    @include typo-headline();
  }

  h2 {
    @include typo-body-lrg();

    &:first-child {
      // margin-bottom:;
    }
  }

  h1, h2,h3,h4,h5,h6 {
    margin-bottom: 0;
  }

  h3 {@include typo-subhead();}

  h4 {@include typo-menu();}

  h5 {@include typo-title();}

  h6 {
    @include typo-body();
    margin-bottom: 0;
  }

  p {
    @include typo-body();
    margin-bottom: $paragraph__margin_bottom * 2;

    a {
      color: $color__agave;
      text-decoration: underline;
      word-wrap: break-word;
      overflow-wrap: anywhere;
      &:hover {
        color: $color__agave_tint_2;
        text-decoration: underline;
      }
    }
  }

  img {
    max-width: 100%;
    display: block;
  }

  + .content-image {
    margin-top: 50px;
  }

}
