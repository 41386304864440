.content-image {
  margin: $gutter__tablet 0;
  img {
    max-width: 100%;
    height: auto;
  }

  &--logo {
    margin: ($gutter__tablet * 2) 0;
  }
}
