// ==========================================================================
// Typography Mixins
// ==========================================================================
// TODO: Cleanup typography variables to match natural language rather than h1-h6.
@mixin typo-headline($text-transform: none) {
  font-size: rem(96px);
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
}


@mixin typo-midline($color: $color__headline_text, $text-transform: none) {
  color: $color;
  font-family: $font__family_midhead;
  font-weight: $font__weight_midhead;
  font-size: $font__size_midhead;
  font-style: normal;
  line-height: $font__line_height_midhead;
}

@mixin typo-subhead($color: $color__headline_text, $text-transform: none) {
  @include font-tracking($font__tracking_subhead);
  color: $color;
  font-family: $font__family_subhead;
  font-weight: $font__weight_subhead;
  font-size: $font__size_subhead;
  font-style: normal;
  line-height: $font__line_height_subhead;
}

@mixin typo-subhead-title($color: $color__headline_text) {
  @include font-tracking($font__tracking_subhead_title);
  color: $color;
  font-family: $font__family_subhead_title;
  font-weight: $font__weight_subhead_title;
  font-size: $font__size_subhead_title;
  font-style: normal;
  line-height: $font__line_height_subhead_title;
}

@mixin typo-menu($color: $color__headline_text, $text-transform: none) {
  @include font-tracking($font__tracking_menu);
  color: $color;
  font-family: $font__family_menu;
  font-weight: $font__weight_menu;
  font-size: $font__size_menu;
  font-style: normal;
  line-height: $font__line_height_menu;
}




@mixin typo-title($color: $color__headline_text) {
  @include font-tracking($font__tracking_title);
  color: $color;
  font-family: $font__family_title;
  font-weight: $font__weight_title;
  font-size: $font__size_title;
  font-style: normal;
  line-height: $font__line_height_title;
}

@mixin typo-label($color: $color__headline_text) {
  @include font-tracking($font__tracking_label);
  color: $color;
  font-family: $font__family_label;
  font-weight: $font__weight_label;
  font-size: $font__size_label;
  font-style: normal;
  line-height: $font__line_height_label;
}

@mixin typo-body-xl($color: $color__headline_text, $with_link_styles: true, $responsive: true) {
  color: $color;
  font-family: $font__family_body_xl;
  font-weight: $font__weight_body_xl;
  font-style: normal;
  line-height: $font__line_height_body_xl;
  @if $with_link_styles {
    @include typo-body-copy-link-styles();
  }
  @if $responsive {
    font-size: $font__size_body_xl_min;
    @include mq (ipad) { font-size: $font__size_body_xl_ipad; }
    @include mq (desktop_sm) { font-size: $font__size_body_xl_desktop_sm; }
    @include mq (desktop_md) { font-size: $font__size_body_xl_desktop_md; }
    @include mq (desktop_lg) { font-size: $font__size_body_xl_desktop_lg; }
    @include mq (desktop_xl) { font-size: $font__size_body_xl_desktop_xl; }
  } @else {
    font-size: $font__size_body_lrg;
  }
}


@mixin typo-body-lrg($color: $color__headline_text, $with_link_styles: true, $responsive: true) {
  color: $color;
  font-family: $font__family_body_lrg;
  font-weight: $font__weight_body_lrg;
  font-style: normal;
  line-height: $font__line_height_body_lrg;
  @if $with_link_styles {
    @include typo-body-copy-link-styles();
  }
  @if $responsive {
    font-size: $font__size_body_lrg_min;
    @include mq (ipad) { font-size: $font__size_body_lrg_ipad; }
    @include mq (desktop_sm) { font-size: $font__size_body_lrg_desktop_sm; }
    @include mq (desktop_md) { font-size: $font__size_body_lrg_desktop_md; }
    @include mq (desktop_lg) { font-size: $font__size_body_lrg_desktop_lg; }
    @include mq (desktop_xl) { font-size: $font__size_body_lrg_desktop_xl; }
  } @else {
    font-size: $font__size_body_lrg;
  }

}

@mixin typo-body-md($color: $color__headline_text, $with_link_styles: false) {
  color: $color;
  font-family: $font__family_body_md;
  font-weight: $font__weight_body_md;
  font-size: $font__size_body_md;
  font-style: normal;
  line-height: $font__line_height_body_md;
  @if $with_link_styles {
    @include typo-body-copy-link-styles();
  }
}


@mixin typo-body($color: $color__body_text, $with_link_styles: true) {
  @include font-tracking($font__tracking_body);
  color: $color;
  font-family: $font__family_body;
  font-weight: $font__weight_body;
  font-size: $font__size_body;
  font-style: normal;
  line-height: $font__line_height_body;
  letter-spacing: 0.3px;
  @if $with_link_styles {
    @include typo-body-copy-link-styles();
  }
}

@mixin typo-micro($color: $color__body_text, $with_link_styles: true) {
  @include font-tracking($font__tracking_micro);
  color: $color;
  font-family: $font__family_micro;
  font-weight: $font__weight_micro;
  font-size: $font__size_micro;
  font-style: normal;
  line-height: $font__line_height_micro;
  @if $with_link_styles {
    @include typo-body-copy-link-styles();
  }
}

@mixin typo-legal($color: $color__body_text, $with_link_styles: true) {
  @include font-tracking($font__tracking_legal);
  color: $color;
  font-family: $font__family_legal;
  font-weight: $font__weight_legal;
  font-size: $font__size_legal;
  font-style: normal;
  line-height: $font__line_height_legal;
  @if $with_link_styles {
    @include typo-body-copy-link-styles();
  }
}


@mixin typo-body-copy-link-styles() {

  a {
    text-decoration: none;
    color: $color__gold;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    @include hover {
      color: $color__gold;
      text-decoration: none;
    }
  }
}
