.page-nav {
  $self: &;
  position: absolute;
  bottom: $site_page_nav_position_bottom;
  display: flex;
  width: 100%;
  height: $site_page_nav_height;

  &--hidden & {
    display: none;
  }

  &__prev,
  &__next {
    text-decoration: none;
    display: inline-block;

    #{$self}__icon {
      width: 18px;
      height: $site_page_nav_height;
      pointer-events: none;
    }

    &.disabled {
      opacity: 0.25;
      cursor: default;
    }
  }

  &__prev {
    margin-right: 10px;
    #{$self}__icon {
      transform: rotate(180deg);
    }
  }

  &__next {
    margin-left: 10px;
  }
}
