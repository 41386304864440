/**
 * Breakpoints
 * @type {[type]}
 */
// Set the $bp_[name] values in the variables file
// https://material.io/guidelines/layout/responsive-ui.html#responsive-ui-breakpoints
$mq_breakpoints: (
  mobile_xs:  320px, // iphone 5
  mobile_sm:  375px, // iphone 6/7/8/X
  mobile_md:  411px, // Pixel 2/2XL & iphone 6/7/8 Plus (414px)
  mobile_lg:  480px, // Begin Material Design Breakpoints
  tablet_sm:  600px,
  tablet_md:  720px,
  ipad: 768px,
  tablet_lg:  840px,
  desktop_sm: 960px,
  desktop_md: 1024px,
  desktop_lg: 1280px,
  desktop_xl: $site__max_width,
);

@if $mq_show_breakpoints {
  $mq_show_breakpoints: (
    mobile_xs,
    mobile_sm,
    mobile_md,
    mobile_lg,
    tablet_sm,
    tablet_md,
    tablet_lg,
    desktop_sm,
    desktop_md,
    desktop_lg,
    desktop_xl,
  );
}



