// sass-lint:disable no-css-comments, space-before-colon, no-trailing-zero
/*============================================================================
  Table of Contents

    1. Content Sizing
    2. Directory Paths & Z-Indexes
    3. Typography
    4. Colors
    5. Module & Component Specific

==============================================================================*/
$mq_show_breakpoints: null !default;
// $debug_breakpoints_position: bottom; // top or bottom
// $debug_layout: false;
// $debug_layers: false;
$debug_baseline_grid: false;
$debug_show_grid_columns: false;
$baseline_grid_line_height: 24px;

/*============================================================================
  Directory Paths
==============================================================================*/
//---- Images
$directory__images: '../img/';
// $svg_directory: 'assets/img/svg/';
// $svg_icon_directory: 'assets/img/svg/icons/';
// $spritesmith_ImgName: 'sprite.png';
// $spritesmith_retinaImgName: 'sprite@2x.png';

/*============================================================================
  Z-Indexes
==============================================================================*/
$z_indexes: (
  modal: 100,
  modal-backdrop: 90,
  mobile-header-hamburger: 12,
  drawer: 20,
  drawer-backdrop: 10,
  mobile-header-logo: 9,
  site-header: 8,
  mobile-site-header: 7,
  site-nav: 6,
  site-nav-panel: 5,
  desktop-nav: 4,
  main-toolbar: 4,
  background-overlay: 3,
  thumbnail-hover: 2,
  thumbnail-hover-img: -1,
  site-content: 1
);

/*============================================================================
  Transitions & Animations
==============================================================================*/
// From Material Design Lite
// https://github.com/google/material-design-lite
// src/_variables.scss#L497
$animation__curve_fast_out_slow_in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animation__curve_linear_out_slow_in: cubic-bezier(0, 0, 0.2, 1) !default;
$animation__curve_fast_out_linear_in: cubic-bezier(0.4, 0, 1, 1) !default;
$animation__curve_default: $animation__curve_fast_out_slow_in !default;

$transition__default_duration: 0.4s;
$transition__default_function: cubic-bezier(0.46, 0.01, 0.32, 1);
$transition__default: $transition__default_duration $transition__default_function;

/*============================================================================
  3. Typography
==============================================================================*/

//---- Font Families
// Fallbacks found using: https://meowni.ca/font-style-matcher/
$sans: forma-djr-text, Tahoma, 'Trebuchet MS', 'Lucida Grande', sans-serif;
$serif: ivyjournal, 'Lucida Bright', Georgia, -apple-system, serif;
$monospace: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', -apple-system, monospace;
$monospace_alt: 'Courier 10 Pitch', Courier, -apple-system, monospace;
// TODO: Add font awesome
// FontAwesome Free Solid (900)

//---- Font Weights
$font__weight_light: 300;
$font__weight_regular: 400;
$font__weight_medium: 500;


$font__base_family: $sans !default;
$font__base_size: 16px; // Always 16px
$font__base_size_min: 16px; // Always 16px
$font__base_weight: $font__weight_regular !default;
$font__base_line_height: 1.67 !default;


// <CODE>
$font__code: $monospace;
$font__code_size: 14px;

// <PRE>
$font__pre: $monospace_alt;
$font__pre_size: 14px;
$font__pre_line_height: 1.6;

// Midline
// utility class: .typo-midline
$font__size_midhead: rem(56px);
$font__size_min_midhead: $font__size_midhead;
$font__line_height_midhead: 1;
$font__weight_midhead: $font__weight_light;
$font__family_midhead: $sans;
$font__tracking_midhead: null;
// Subhead
// utility class: .subhead-text
$font__size_subhead: rem(24px);
$font__size_min_subhead: $font__size_subhead;
$font__line_height_subhead: 1.167;
$font__weight_subhead: $font__weight_medium;
$font__family_subhead: $sans;
$font__tracking_subhead: 10;
// Subhead Title
// utility class: .typo-subhead-title
$font__size_subhead_title: rem(20px);
$font__size_min_subhead_title: $font__size_subhead_title;
$font__line_height_subhead_title:1;
$font__weight_subhead_title: $font__weight_regular;
$font__family_subhead_title: $sans;
$font__tracking_subhead_title: 10;
// Menu
// utility class: .menu-text
$font__size_menu: rem(18px);
$font__size_min_menu: $font__size_menu;
$font__line_height_menu: 1.111; // old 1.25
$font__weight_menu: $font__weight_medium;
$font__family_menu: $sans;
$font__tracking_menu: 10;

// Title
// utility class: .title-text
$font__size_title: rem(16px);
$font__size_min_title: $font__size_title;
$font__line_height_title: 1.25;
$font__weight_title: $font__weight_medium;
$font__family_title: $sans;
$font__tracking_title: 10;
// Label
// utility class: .title-text
$font__size_label: rem(13px);
$font__size_min_label: $font__size_label;
$font__line_height_label: 1.385;
$font__weight_label: $font__weight_medium;
$font__family_label: $sans;
$font__tracking_label: 10;
// utility class: .body-text-xl
$font__size_body_xl_min: rem(34px);
$font__size_body_xl_ipad:rem(38px);
$font__size_body_xl_desktop_sm:rem(40px);
$font__size_body_xl_desktop_md:rem(42px);
$font__size_body_xl_desktop_lg:rem(44px);
$font__size_body_xl_desktop_xl: rem(46px);
$font__size_body_xl: $font__size_body_xl_desktop_xl;
$font__line_height_body_xl: 1.467;
$font__weight_body_xl: $font__weight_regular;
$font__family_body_xl: $serif;
$font__tracking_body_xl: null;

// Body Large
// utility class: .body-text-lrg
$font__size_body_lrg_min: rem(20px);
$font__size_body_lrg_ipad:rem(22px);
$font__size_body_lrg_desktop_sm:rem(24px);
$font__size_body_lrg_desktop_md:rem(26px);
$font__size_body_lrg_desktop_lg:rem(28px);
$font__size_body_lrg_desktop_xl: rem(30px);
$font__size_body_lrg: $font__size_body_lrg_desktop_xl;
$font__line_height_body_lrg: 1.467;
$font__weight_body_lrg: $font__weight_regular;
$font__family_body_lrg: $serif;
$font__tracking_body_lrg: null;
// Body Medium
// utility class: .typo-body-md
$font__size_body_md: rem(18px);
$font__size_min_body_md: $font__size_body_md;
$font__line_height_body_md: 1.66667;
$font__weight_body_md: $font__weight_regular;
$font__family_body_md: $serif;
$font__tracking_body_md: null;
// Body
// utility class: .body-text
$font__size_body: rem(15px);
$font__size_min_body: $font__size_body;
$font__line_height_body: $font__base_line_height;
$font__weight_body: $font__weight_regular;
$font__family_body: $sans;
$font__tracking_body: 0.3px;
// Micro
// utility class: .micro-text
$font__size_micro: rem(13px);
$font__size_min_micro: $font__size_micro;
$font__line_height_micro: 1.5;
$font__weight_micro: $font__weight_regular;
$font__family_micro: $sans;
$font__tracking_micro: 10;
// Legal
// utility class: .legal-text
$font__size_legal: rem(13px);
$font__size_min_legal: $font__size_legal;
$font__line_height_legal: 1.538;
$font__weight_legal: $font__weight_regular;
$font__family_legal: $sans;
$font__tracking_legal: 10;


// <dt>
$font__dt_weight: $font__weight_medium !default;

/*============================================================================
  4. Colors
==============================================================================*/
/* stylelint-disable */

/* stylelint-enable */

// ===============================
// PRIMARY PALETTE and TINTS
// ===============================
$color__almost_black:        #1a1d1b;
$color__almost_black_tint_1: #303331;
$color__pure_black:          #000;

$color__dark_grey:#626562;

$color__pure_white:#fff;
$color__warm_white:#f0efed;

$color__gold:         #f1c850;
$color__gold_tint_1:  #efd68b;
$color__gold_tint_2:  #f1e3b9;
$color__gold_tint_3:  #f5eed9;

$color__agave:         #828c7c;
$color__agave_tint_1:  #a8afa4;
$color__agave_tint_2:  #c8ccc5;
$color__agave_tint_3:  #e0e2de;
// ===============================
// SECONDARY PALETTE and TINTS
// ===============================
$color__surf:        #97c5cc;
$color__surf_tint_1: #b9d5d9;
$color__surf_tint_2: #d3e3e5;
$color__surf_tint_3: #e7eff0;

$color__firesticks:        #ee795a;
$color__firesticks_tint_1: #ee795a;
$color__firesticks_tint_2: #f1c7bd;
$color__firesticks_tint_3: #f5e0db;

$color__moss:        #bac55f;
$color__moss_tint_1: #cbd393;
$color__moss_tint_2: #dde0bd;
$color__moss_tint_3: #ebecda;

$color__navy_teal:        #255665;
$color__navy_teal_tint_1: #6a8992;
$color__navy_teal_tint_2: #a2b3b8;
$color__navy_teal_tint_3: #cad3d5;

$color__prickly_pear:        #c6d6bc;
$color__prickly_pear_tint_1: #dbe2d1;
$color__prickly_pear_tint_2: #e6ece2;
$color__prickly_pear_tint_3: #f1f4ef;

//---- LEGACY GREY COLORS (reassigned)
// $mid_grey => $color__agave_tint_2
// $light_grey => $color__agave_tint_3;


//---- Helper colors
$color__disabled: $color__agave_tint_2;
$color__disabled_border: darken($color__disabled, 25%);
$color__error: $color__firesticks;
$color__error_background: $color__warm_white;
$color__success: $color__agave_tint_2;
$color__success_background: $color__gold;
$color__focus_outline: rgba($color__warm_white, 1);
// Body
//
// Settings for the `<body>` element.
$color__body_background: $color__pure_white !default;
$color__body_text: $color__almost_black !default;
$color__headline_text: $color__almost_black !default;

// Links
//
// Style anchor `<a>` elements.
$link__color: $color__gold !default;
$link__color_hover: darken($link__color, 15%) !default;
$link__decoration: none !default;
$link__decoration_hover: underline !default;
$focus__outline_style: 1px dotted $color__focus_outline;
/* Borders */
$border__width: 1px !default;
$color__border: $color__warm_white;

/* Horizontal Rules */
// Style anchor `<hr>` elements.
$width__hr_border: 3px !default;
$color__hr: $color__warm_white;

/* Selections */
$color__selection: lighten($color__almost_black, 4%);
$color__selection_background: $color__gold_tint_1;

/*============================================================================
  1. Structure Content Sizing
==============================================================================*/
/**
 *
 * PSD/Design Canvas Width
 * Used as context for calculating top/bottom margin percentages
 *
 */
// width of design file canvas
$site__canvas_width: 1920px;

// Gutters
$gutter__mobile: 20px;
$gutter__tablet: 30px;
$gutter__desktop: 40px;

// Width of design content width (left/right flush)
$site__max_width: $site__canvas_width;
$site__max_width_content: $site__max_width - ($gutter__desktop * 2);

// Add 2x site gutter when calculating elements that are not full bleed
$site__max_width_including_gutters: $site__max_width + ($gutter__desktop * 2);
$site__sub_nav_height: 50px;
$site__header_height_desktop: 81px;
$site__header_height_mobile: 61px;
$site__header_shadow: 0 3px 0 rgba($color__warm_white, 1);
$site__main_width: 100%;
$site__sidebar_width: 25%;
$site__alertbanner_height: 54px;

//-- GRID
$gutter__mobile: 20px;
$gutter__mobile_percent: percentage($gutter__mobile / $site__canvas_width);
$gutter__mobile_half: ($gutter__mobile / 2);
$gutter__mobile_split_percent: percentage($gutter__mobile_half / $site__canvas_width);

$gutter__desktop: 30px;
$gutter__desktop_percent: percentage($gutter__desktop / $site__canvas_width);
$gutter__desktop_half: ($gutter__desktop / 2);
$gutter__desktop_split_percent: percentage($gutter__desktop_half / $site__canvas_width);

$column__width_px: 268px;
$column__width_percent: 20%;

/*============================================================================
  5. Module/Component Settings
==============================================================================*/
// ---- Site Nav

// -------- Top Level
$site_nav__height_desktop: $site__header_height_desktop;
$site_nav__height_mobile: $site__header_height_mobile;
$site_nav__item_padding_large: 13px;
$site_nav__item_padding_xlarge: 13px;
$site_nav__color_text: $color__almost_black;
$site_nav__color_text_opacity: 1;
$site_nav__background_color: $color__pure_white;
$site_nav__background_color_opacity: 1;

// -------- Top Level -- Hover
$site_nav__color_text_hover: $color__almost_black;
$site_nav__color_text_opacity_hover: 1;
$site_nav__background_color_hover: $color__pure_white;
$site_nav__background_color_opacity_hover: 1;
$site_nav__underline_color: $color__gold;

// ------------2nd Level & Below
$site_nav__sub_menu_text: $color__firesticks;
$site_nav__sub_menu_text_opacity: 1;
$site_nav__sub_menu_bg: $color__agave;
$site_nav__sub_menu_bg_opacity: 1;

// ------------ 2nd Level & Below -- Hover
$site_nav__sub_menu_text_hover: $color__pure_white;
$site_nav__sub_menu_text_opacity_hover: 1;
$site_nav__sub_menu_bg_hover: $color__almost_black;
$site_nav__sub_menu_bg_opacity_hover: 1;

$site_masthead_height: 50px;
$site_page_nav_height: 30.4px;
$site_page_nav_position_bottom: 40px;
$site_page_nav_content_offset: $site_page_nav_height + $site_page_nav_position_bottom;
$site_content_padding_left: 6.46vw;
// ---- Toolbars

//-- Main toolbar
$toolbar__height: 46px;
// Cart toolbar
$cartbar__height: 65px;

// ==========================================================================
// Drawers
// ==========================================================================

// Nav Drawer (drawer--left)
$nav_drawer__width: 328px;
$nav_drawer__background_color: $color__almost_black;

// Nav Drawer Menu  (.drawer__menu)
$nav_drawer_menu__gutter: $gutter__mobile;
$nav_drawer_menu__color_text: $color__pure_white;
$nav_drawer_menu__text_transform: none;
$nav_drawer_menu__font_weight: $font__weight_regular;
$nav_drawer_menu__font_family: $sans;
$nav_drawer_menu__font_size: rem(24px);

// Nav Drawer Menu Header Vars
$nav_drawer_menu__color_text_header: rgba(#a9acb2, 1);
$nav_drawer_menu__background_color_header: rgba(#3c3d40, 1);
$nav_drawer_menu__font_weight_header: $font__weight_medium;
$nav_drawer_menu__font_family_header: $sans;
$nav_drawer_menu__font_size_header: rem(18px);
$nav_drawer_menu__spacer_header: 15px;

/*
   Borders
   ========================================================================== */
$default__border: 1px solid rgba($color__border, 0.75);

/*
   <BUTTON>
========================================================================== */
$button__font_family: $sans;
$button__font_size: rem(14px);
$button__font_weight: $font__weight_medium;
$button__line_height: 1;
$button__color_text: $color__pure_white;
$button__color_text_hover: $color__pure_white;
$button__background_color: $color__dark_grey;
$button__background_color_hover: $color__dark_grey;
$button__border_radius: 3px;
$button__color_text_disabled: $color__agave_tint_1;
$button__background_color_disabled: $color__agave_tint_3;
$button__padding: rem(11.5px) rem(14px) rem(8.5px) rem(14px);
$button__focus_outline_width: thin;
$button__focus_outline_color: $color__warm_white;
$button__focus_outline_style: dotted;
$button__focus_outline: $button__focus_outline_width $button__focus_outline_style $button__focus_outline_color;
$button__text_transform: uppercase;

$form__input_padding_top: 16px;
$form__input_padding_right: 14px;
$form__input_padding_bottom: 12px;
$form__input_padding_left: 14px;
$form__input_padding: $form__input_padding_top $form__input_padding_right $form__input_padding_bottom $form__input_padding_left;
// Headings
//
// Style h1 - h6 element.
$text__margin_bottom_xxs: rem($gutter__mobile * 0.1);
$text__margin_bottom_xs: rem($gutter__mobile * 0.15);
$text__margin_bottom_sm: rem($gutter__mobile * 0.25);
$text__margin_bottom_normal: rem($gutter__mobile * 0.5);

$text__margin_bottom_med: rem($gutter__mobile * 0.75);
$text__margin_bottom_lg: rem($gutter__desktop * 0.5);
$text__margin_bottom_xl: rem($gutter__desktop * 0.75);
// Paragraphs
//
// Style p element.
$paragraph__margin_bottom: rem(10px);
$paragraph__font_family: $serif;

// Horizontal Rules <hr>
$spacing__hr_mobile: $gutter__mobile * 1.5 - ($width__hr_border / 2);
$spacing__hr_desktop: $gutter__desktop * 1.5 - ($width__hr_border / 2);
$spacing__hr_placeholder_mobile: 60px;
$spacing__hr_placeholder_desktop: 90px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table__bg: transparent !default;
$table__border_width: $border__width !default;

$table__font_family: $sans;
$table__caption_side: top;
$table__head_bg: $color__dark_grey !default;
$table__head_color: $color__pure_white !default;
$table__head_cell_padding_vertical: $gutter__mobile_half !default;
$table__body_color: $color__dark_grey !default;
$table__body_cell_bg: $color__warm_white !default;
$table__cell_padding_vertical: $gutter__mobile - 4px !default;
$table__cell_padding_horizontal: $gutter__mobile_half !default;
$table__cell_padding_sm: 0.3rem !default;
$table__striped_order: odd !default;

// sass-lint enable no-css-comments, space-before-colon, no-trailing-zero
