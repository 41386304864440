// ==========================================================================
// Top Level page containers (mostly)
//
// Implemented flexbox sticky footer styles here per this reduced case on codepen.
// Pen: https://codepen.io/j-cam/pen/4818f2dea635a5e0fe31623bd1e06ec6/
// Forked from: https://codepen.io/chriscoyier/pen/RRbKrL
// Article: https://css-tricks.com/couple-takes-sticky-footer/
// ==========================================================================
$module: 'layout';

.#{$module} {

  // LOCAL VARS
  $self: &;
  $self_sidebar_width: 258px;
  $self_sidebar_width_percent: percentage($self_sidebar_width / 1920px);
  $self_content_max_width: 1920px - $self_sidebar_width;


  // * {
  //   @include debug-outline;
  // }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    height: 100vh;
    min-height: 100%;
    min-height: 100vh;/* Avoid the IE 10-11 `min-height` bug. */
    overflow: hidden;
  }

  &__site {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &-inner {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }

    &-sidebar {
      @include material-animation-fast-out-slow-in(250ms);
      width: $self_sidebar_width_percent;
      min-width: $self_sidebar_width;
      max-width: $self_sidebar_width;
      z-index: 3;
      position: fixed;
      top: 0;
      bottom: 0;
      left: $self_sidebar_width * -1;
      height: 100%;
      background-color: $color__pure_white;

      &:after {
        content: '';
        width: $self_sidebar_width_percent;
        min-width: $self_sidebar_width;
        max-width: $self_sidebar_width;
        height: 60px;
        // background: linear-gradient(transparent 10px, $color__pure_white);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
      }
      @include mq(desktop_sm) {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
      }
    }

    &--show-nav &-sidebar {
      transform: translateX($self_sidebar_width);
      border-right: 1px solid $color__warm_white;
      @include mq(desktop_sm) {
        display: block;
        position: relative;
        transform: none;
        border-right: none;
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
      }
    }

    &-main {
      width: 100%;
      height: 100%;

      overflow-y: scroll;

      @include mq(desktop_md) {
         width: calc(100% - #{$self_sidebar_width});
      }
    }

    &--show-nav &-main {
      overflow-y: hidden;
      @include mq(desktop_sm) {
        overflow-y: scroll;
      }
    }

    &-nav-overlay {
      display: none;
      position: fixed;
      background-color: rgba($color__almost_black, 0.16);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
    }
    &--show-nav &-nav-overlay {
      display: block;
      @include mq(desktop_sm) {
        display: none;
      }
    }



  }
}
