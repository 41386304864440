.color-concept-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 736px;
  margin-top: 40px;
  margin-bottom: 80px;

  .color-concept {
    width: 100%;
    max-width: 250px;
    @include mq(mobile_sm) {
      // max-width: 200px;
    }
    @include mq(desktop_sm) {
      max-width: 300px;
      width: percentage(1 / 2);
    }
  }
}
