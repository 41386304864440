/* stylelint-disable */
/**
 * px to em conversion
 * @link http://www.pjmccormick.com/sweet-sass-function-convert-px-em
 * @requires {variable} $font__base_size
 * @param {number} $target - size to convert
 * @param {number} $context ($font__base_size) - context conversion is based on
 * @example scss
 * .foobar { padding-bottom: em(30); }
 * @returns {number}
 */
/**
 * px to rem
 * @link http://www.pjmccormick.com/sweet-sass-function-convert-px-em
 * @requires {variable} $font__base_size
 * @param {number} $target - size to convert
 * @param {number} $context ($font__base_size) - context conversion is based on
 * @example scss
 * .foobar { padding-bottom: rem(30); }
 * @returns {number}
 */
/**
 * em to px conversion
 * @link http://www.pjmccormick.com/sweet-sass-function-convert-px-em
 * @requires {variable} $font__base_size
 * @param {number} $target - size to convert
 * @param {number} $context ($font__base_size) - context conversion is based on
 * @example scss
 * .foobar { padding-bottom: px(30); }
 * @returns {number}
 */
/**
 * Aspect Ratio
 * @param {number} $ratio-numerator - ratio numerator
 * @param {number} $ratio-denominator - ratio denominator
 * @example scss
 * .foobar { padding-bottom: aspect-ratio(16,9); } // @returns 56.25%
 * @returns {number}
 */
/**
 * Aspect Ratio
 * Based on Fixed Image Dimensions
 * @param {number} $w - image width
 * @param {number} $h - image height
 * @example scss
 * .foobar { padding: 0 0 aspect-ratio-fixed(1080, 720); } // @returns 66.666666666667%
 * @returns {number}
 */
/**
 * Context Calculator
 * @param {number} $target
 * @param {number} $context
 * @example scss
 * .foobar { padding-bottom: context-calc(30,15); }
 * @returns {number}
 */
/**
 * Strip Unit Values
 * @link http://hugogiraudel.com/2013/08/12/sass-functions
 * @param {string} $num
 * @example scss
 * .foobar { padding-bottom: strip-unit(30px); }
 * @returns {number}
 */
/**
 * Z
 * @param  string name of key
 * @return string the key value or warning
 */
/* stylelint-enable */
/*============================================================================
  Table of Contents

    1. Content Sizing
    2. Directory Paths & Z-Indexes
    3. Typography
    4. Colors
    5. Module & Component Specific

==============================================================================*/
/*============================================================================
  Directory Paths
==============================================================================*/
/*============================================================================
  Z-Indexes
==============================================================================*/
/*============================================================================
  Transitions & Animations
==============================================================================*/
/*============================================================================
  3. Typography
==============================================================================*/
/*============================================================================
  4. Colors
==============================================================================*/
/* stylelint-disable */
/* stylelint-enable */
/* Borders */
/* Horizontal Rules */
/* Selections */
/*============================================================================
  1. Structure Content Sizing
==============================================================================*/
/**
 *
 * PSD/Design Canvas Width
 * Used as context for calculating top/bottom margin percentages
 *
 */
/*============================================================================
  5. Module/Component Settings
==============================================================================*/
/*
   Borders
   ========================================================================== */
/*
   <BUTTON>
========================================================================== */
/**
 * Breakpoints
 * @type {[type]}
 */
/* Animations */
body::before {
  top: auto;
  bottom: 0;
}

/*============================================================================
  # Utility Classes
==============================================================================*/
/* stylelint-disable declaration-no-important */
.is-transitioning {
  display: block !important;
  visibility: visible !important;
}

.sr,
.sr-text,
.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* stylelint-enable declaration-no-important */
.headline-text,
.typo-headline,
.headline-text p,
.typo-headline p {
  font-size: 6rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
}
.headline-text p,
.typo-headline p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.midline-text,
.typo-midline,
.midline-text p,
.typo-midline p {
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 3.5rem;
  font-style: normal;
  line-height: 1;
}
.midline-text p,
.typo-midline p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.subhead-text,
.typo-subhead,
.subhead-text p,
.typo-subhead p {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.167;
}
.subhead-text p,
.typo-subhead p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.subhead-title-text,
.typo-subhead-title,
.subhead-title-text p,
.typo-subhead-title p {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  font-style: normal;
  line-height: 1;
}
.subhead-title-text p,
.typo-subhead-title p {
  font-size: 36px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.menu-text,
.typo-menu,
.menu-text p,
.typo-menu p {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.111;
}
.menu-text p,
.typo-menu p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.title-text,
.typo-title,
.title-text p,
.typo-title p {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.25;
}
.title-text p,
.typo-title p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.label-text,
.typo-label,
.label-text p,
.typo-label p {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  font-style: normal;
  line-height: 1.385;
}
.label-text p,
.typo-label p {
  font-size: 2.25rem;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.body-text-xl,
.typo-body-xl,
.body-text-xl p,
.typo-body-xl p {
  color: #1a1d1b;
  font-family: ivyjournal, "Lucida Bright", Georgia, -apple-system, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.467;
  font-size: 2.125rem;
}
.body-text-xl a,
.typo-body-xl a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.body-text-xl a:hover,
.typo-body-xl a:hover {
  color: #f1c850;
  text-decoration: none;
}
@media (min-width: 48em) {
  .body-text-xl,
.typo-body-xl,
.body-text-xl p,
.typo-body-xl p {
    font-size: 2.375rem;
  }
}
@media (min-width: 60em) {
  .body-text-xl,
.typo-body-xl,
.body-text-xl p,
.typo-body-xl p {
    font-size: 2.5rem;
  }
}
@media (min-width: 64em) {
  .body-text-xl,
.typo-body-xl,
.body-text-xl p,
.typo-body-xl p {
    font-size: 2.625rem;
  }
}
@media (min-width: 80em) {
  .body-text-xl,
.typo-body-xl,
.body-text-xl p,
.typo-body-xl p {
    font-size: 2.75rem;
  }
}
@media (min-width: 120em) {
  .body-text-xl,
.typo-body-xl,
.body-text-xl p,
.typo-body-xl p {
    font-size: 2.875rem;
  }
}
.body-text-xl p,
.typo-body-xl p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.body-text-lrg,
.typo-body-lrg,
.body-text-lrg p,
.typo-body-lrg p {
  color: #1a1d1b;
  font-family: ivyjournal, "Lucida Bright", Georgia, -apple-system, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.467;
  font-size: 1.25rem;
}
.body-text-lrg a,
.typo-body-lrg a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.body-text-lrg a:hover,
.typo-body-lrg a:hover {
  color: #f1c850;
  text-decoration: none;
}
@media (min-width: 48em) {
  .body-text-lrg,
.typo-body-lrg,
.body-text-lrg p,
.typo-body-lrg p {
    font-size: 1.375rem;
  }
}
@media (min-width: 60em) {
  .body-text-lrg,
.typo-body-lrg,
.body-text-lrg p,
.typo-body-lrg p {
    font-size: 1.5rem;
  }
}
@media (min-width: 64em) {
  .body-text-lrg,
.typo-body-lrg,
.body-text-lrg p,
.typo-body-lrg p {
    font-size: 1.625rem;
  }
}
@media (min-width: 80em) {
  .body-text-lrg,
.typo-body-lrg,
.body-text-lrg p,
.typo-body-lrg p {
    font-size: 1.75rem;
  }
}
@media (min-width: 120em) {
  .body-text-lrg,
.typo-body-lrg,
.body-text-lrg p,
.typo-body-lrg p {
    font-size: 1.875rem;
  }
}
.body-text-lrg p,
.typo-body-lrg p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.body-text-md,
.typo-body-md,
.body-text-md p,
.typo-body-md p {
  color: #1a1d1b;
  font-family: ivyjournal, "Lucida Bright", Georgia, -apple-system, serif;
  font-weight: 400;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.66667;
}
.body-text-md p,
.typo-body-md p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.body-text,
.typo-body,
.body-text p,
.typo-body p {
  letter-spacing: 0.0003pxem;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.3px;
}
.body-text a,
.typo-body a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.body-text a:hover,
.typo-body a:hover {
  color: #f1c850;
  text-decoration: none;
}
.body-text p,
.typo-body p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
  font-size: 2.25rem;
  line-height: 1.33;
}

.micro-text,
.typo-micro,
.micro-text p,
.typo-micro p {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  font-style: normal;
  line-height: 1.5;
}
.micro-text a,
.typo-micro a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.micro-text a:hover,
.typo-micro a:hover {
  color: #f1c850;
  text-decoration: none;
}
.micro-text p,
.typo-micro p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.legal-text,
.typo-legal,
.legal-text p,
.typo-legal p {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  font-style: normal;
  line-height: 1.538;
}
.legal-text a,
.typo-legal a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.legal-text a:hover,
.typo-legal a:hover {
  color: #f1c850;
  text-decoration: none;
}
.legal-text p,
.typo-legal p {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.bg-warm-white {
  background-color: #f0efed;
}

.bg-gold {
  background-color: #f1c850;
}

.bg-gold-tint-1 {
  background-color: #efd68b;
}

.bg-gold-tint-2 {
  background-color: #f1e3b9;
}

.bg-gold-tint-3 {
  background-color: #f5eed9;
}

.bg-agave {
  background-color: #828c7c;
}

.bg-agave-tint-1 {
  background-color: #a8afa4;
}

.bg-agave-tint-2 {
  background-color: #c8ccc5;
}

.bg-agave-tint-3 {
  background-color: #e0e2de;
}

.bg-surf {
  background-color: #97c5cc;
}

.bg-surf-tint-1 {
  background-color: #b9d5d9;
}

.bg-surf-tint-2 {
  background-color: #d3e3e5;
}

.bg-surf-tint-3 {
  background-color: #e7eff0;
}

.bg-firesticks {
  background-color: #ee795a;
}

.bg-firesticks-tint-1 {
  background-color: #ee795a;
}

.bg-firesticks-tint-2 {
  background-color: #f1c7bd;
}

.bg-firesticks-tint-3 {
  background-color: #f5e0db;
}

.bg-moss {
  background-color: #bac55f;
}

.bg-moss-tint-1 {
  background-color: #cbd393;
}

.bg-moss-tint-2 {
  background-color: #dde0bd;
}

.bg-moss-tint-3 {
  background-color: #ebecda;
}

.bg-navy-teal {
  background-color: #255665;
}

.bg-navy-teal-tint-1 {
  background-color: #6a8992;
}

.bg-navy-teal-tint-2 {
  background-color: #a2b3b8;
}

.bg-navy-teal-tint-3 {
  background-color: #cad3d5;
}

.bg-prickly-pear {
  background-color: #c6d6bc;
}

.bg-prickly-pear-tint-1 {
  background-color: #dbe2d1;
}

.bg-prickly-pear-tint-2 {
  background-color: #e6ece2;
}

.bg-prickly-pear-tint-3 {
  background-color: #f1f4ef;
}

/* stylelint-disable */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  background-color: #fff;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.67;
  margin: 0;
  text-align: left;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  border: none;
  border-top-color: #f0efed;
  border-top-style: solid;
  border-top-width: 3px;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.625rem;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: 0.625rem;
  letter-spacing: 0.0003pxem;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.3px;
}
p a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
p a:hover {
  color: #f1c850;
  text-decoration: none;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 0 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

embed,
iframe,
video {
  max-width: 100%;
}

video {
  height: auto;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: top;
}

th {
  text-align: inherit;
}

button {
  border-radius: 0;
}

a:focus,
button:focus {
  outline: 1px dotted #f0efed;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  font-style: normal;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

::selection {
  color: #242825;
  background: #efd68b;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* stylelint-enable */
.layout__body {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  height: 100vh;
  min-height: 100%;
  min-height: 100vh;
  /* Avoid the IE 10-11 `min-height` bug. */
  overflow: hidden;
}
.layout__site {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.layout__site-inner {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.layout__site-sidebar {
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 13.4375%;
  min-width: 258px;
  max-width: 258px;
  z-index: 3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -258px;
  height: 100%;
  background-color: #fff;
}
.layout__site-sidebar:after {
  content: "";
  width: 13.4375%;
  min-width: 258px;
  max-width: 258px;
  height: 60px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
@media (min-width: 60em) {
  .layout__site-sidebar {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
  }
}
.layout__site--show-nav .layout__site-sidebar {
  transform: translateX(258px);
  border-right: 1px solid #f0efed;
}
@media (min-width: 60em) {
  .layout__site--show-nav .layout__site-sidebar {
    display: block;
    position: relative;
    transform: none;
    border-right: none;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
  }
}
.layout__site-main {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
@media (min-width: 64em) {
  .layout__site-main {
    width: calc(100% - 258px);
  }
}
.layout__site--show-nav .layout__site-main {
  overflow-y: hidden;
}
@media (min-width: 60em) {
  .layout__site--show-nav .layout__site-main {
    overflow-y: scroll;
  }
}
.layout__site-nav-overlay {
  display: none;
  position: fixed;
  background-color: rgba(26, 29, 27, 0.16);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.layout__site--show-nav .layout__site-nav-overlay {
  display: block;
}
@media (min-width: 60em) {
  .layout__site--show-nav .layout__site-nav-overlay {
    display: none;
  }
}

.masthead {
  width: 100%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 50px;
  background-color: #626562;
  z-index: 2;
}
.masthead__last-edited {
  color: #fff;
  margin: 0;
  padding-right: 20px;
}
@media (min-width: 37.5em) {
  .masthead__last-edited {
    padding-right: 30px;
  }
}
@media (min-width: 60em) {
  .masthead__last-edited {
    padding-right: 30px;
  }
}
.masthead__last-edited span {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
}
.masthead__last-edited time {
  width: 312px;
  height: 21px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.3px;
  text-align: right;
  color: #ffffff;
}
@media (min-width: 64em) {
  .masthead {
    width: calc(100% - 258px);
  }
}

.hamburger {
  visibility: visible;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 15px 15px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}
@media (min-width: 60em) {
  .hamburger {
    visibility: hidden;
  }
}

.hamburger-box {
  width: 22px;
  height: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
  width: 22px;
  height: 2px;
  background-color: #fff;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::after, .hamburger-inner::before {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -4px;
}
.hamburger-inner::after {
  bottom: -4px;
}

.hamburger-label {
  font-weight: 500;
  font-size: 0.875rem;
  color: white;
  line-height: 10px;
  display: inline-block;
  position: relative;
  margin-left: 4px;
  text-transform: uppercase;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.hamburger--active .hamburger-inner, .hamburger--active .hamburger-inner::after, .hamburger--active .hamburger-inner::before {
  background-color: #fff;
}
.hamburger--active .hamburger-label {
  color: #fff;
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -8px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--active .hamburger-inner {
  transform: translate3d(0, -4px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.content {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-left: 1px solid #f0efed;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
}
@media (min-width: 48em) {
  .content {
    flex-direction: row;
    padding-right: 0;
  }
}
@media (min-width: 80em) {
  .content {
    padding-left: 6.46vw;
  }
}
.content--no-content-header .content__main {
  padding-top: 40px;
}
@media (min-width: 37.5em) {
  .content--no-content-header .content__main {
    padding-top: 85px;
  }
}
.content__intro-slide {
  position: relative;
  width: 100%;
  height: calc(100vh - (50px) );
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content__intro-slide-headline {
  font-size: 6rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0.2395833333em;
}
.content__intro-slide-byline {
  color: #1a1d1b;
  font-family: ivyjournal, "Lucida Bright", Georgia, -apple-system, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.467;
  font-size: 1.25rem;
}
.content__intro-slide-byline a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.content__intro-slide-byline a:hover {
  color: #f1c850;
  text-decoration: none;
}
@media (min-width: 48em) {
  .content__intro-slide-byline {
    font-size: 1.375rem;
  }
}
@media (min-width: 60em) {
  .content__intro-slide-byline {
    font-size: 1.5rem;
  }
}
@media (min-width: 64em) {
  .content__intro-slide-byline {
    font-size: 1.625rem;
  }
}
@media (min-width: 80em) {
  .content__intro-slide-byline {
    font-size: 1.75rem;
  }
}
@media (min-width: 120em) {
  .content__intro-slide-byline {
    font-size: 1.875rem;
  }
}
@media (min-width: 48em) {
  .content__intro-slide {
    padding-left: 20px;
    padding-right: 40px;
  }
}
.content__main {
  position: relative;
  max-width: 920px;
  min-width: 320px;
  flex: 3;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 0 130.4px 0;
}
@media (min-width: 48em) {
  .content__main {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 80em) {
  .content__main {
    padding-left: 0;
  }
}
.content__notes {
  margin-left: auto;
  background-color: #f1f4ef;
  padding: 38px 38px 40px 40px;
}
@media (min-width: 48em) {
  .content__notes {
    max-width: 276px;
  }
}
@media (min-width: 64em) {
  .content__notes {
    flex: 1.5;
    max-width: 396px;
  }
}
.content--has-notes {
  padding-bottom: 60px;
}
@media (min-width: 48em) {
  .content--has-notes {
    padding-bottom: 0;
  }
}
.content--has-notes .content__main {
  padding-bottom: 30px;
}
@media (min-width: 48em) {
  .content--has-notes .content__main {
    padding-bottom: 130.4px;
  }
}
.content--has-notes .page-nav {
  visibility: hidden;
}
@media (min-width: 48em) {
  .content--has-notes .page-nav {
    visibility: visible;
  }
}
.content--has-notes > .page-nav {
  visibility: visible;
  position: relative;
  bottom: auto;
  margin-top: 30px;
}
@media (min-width: 48em) {
  .content--has-notes > .page-nav {
    display: none;
    visibility: hidden;
  }
}

.notes p br {
  content: "";
  margin: 0.25em;
  display: block;
}

.splash {
  display: flex;
  flex-direction: column;
  background-color: #626562;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}
.splash__header {
  height: 8.3950617284vh;
  display: flex;
  align-items: center;
}
.splash__body {
  height: 81.6049382716vh;
  display: flex;
  align-items: center;
}
.splash__footer {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.splash__header, .splash__body, .splash__footer {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.splash__home {
  margin: 0;
}
.splash__home-link {
  display: block;
  width: 14.1975308642vw;
  min-width: 5.390625rem;
  max-width: 7.1875rem;
  height: 5.2839506173vh;
  min-height: 2.00625rem;
  max-height: 2.675rem;
  margin: 0;
}
.splash__home-link-logo {
  fill: #fff;
}
.splash__lockup {
  object-fit: contain;
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
}
.splash__lockup-monogram {
  fill: #828c7c;
}
.splash__lockup-date {
  color: #fff;
  fill: #fff;
  font-size: 18px;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.splash__lockup-title {
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-size: 140px;
  font-weight: 300;
  fill: #fff;
}
.splash__lockup-bg-mask {
  fill: #626562;
}
.splash__enter-link {
  height: 1.125rem;
  width: 1.898125rem;
  display: block;
}
.splash__enter-link-icon {
  fill: #fff;
  width: 100%;
  height: 100%;
}
@media (min-width: 37.5em) {
  .splash__header, .splash__body, .splash__footer {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 60em) {
  .splash__header, .splash__body, .splash__footer {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.content-header {
  margin: 0;
  font-size: 96px;
  padding-top: 0;
  width: 100%;
  padding-top: 0.5em;
  font-size: 6rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
}
@media (min-width: 37.5em) {
  .content-header {
    padding-top: 0.5em;
  }
}
.content-header h1 {
  font-size: 1em;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  letter-spacing: normal;
  text-transform: uppercase;
  transform: translateY(0.5em);
  margin-bottom: 1em;
}
.content-header--divided {
  background-color: #fff;
  padding-left: 20px;
}
.content-header--divided h1 {
  margin-bottom: 0;
}
@media (min-width: 64em) {
  .content-header--divided {
    padding-left: 60px;
  }
}
@media (min-width: 80em) {
  .content-header--divided {
    padding-left: 6.46vw;
    margin-top: 50px;
  }
}
.content-header--divided + .content {
  padding-top: 85px;
}

.content-image {
  margin: 30px 0;
}
.content-image img {
  max-width: 100%;
  height: auto;
}
.content-image--logo {
  margin: 60px 0;
}

.site-nav {
  padding: 26px 35px;
  position: relative;
  height: 100%;
  overflow-y: scroll;
}
.site-nav__home-link {
  display: block;
  width: 7.1875rem;
  height: 2.675rem;
}
.site-nav__list {
  list-style: none;
  padding: 4.7625rem 0 0 0;
  margin: 0;
}
.site-nav__list li {
  list-style: none;
}
.site-nav__list-label {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.36px;
  text-align: left;
}
.site-nav__sublist {
  list-style: none;
  padding: 0 0 35px 0;
}
.site-nav__sublist li {
  list-style: none;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.3px;
  text-align: left;
}
.site-nav__sublist li a {
  color: inherit;
  text-decoration: none;
}
.site-nav__link {
  position: relative;
  background-repeat: no-repeat;
  background-size: 8px 13px;
  background-position: 0 1px;
  padding-left: 18px;
  text-decoration: none;
}
.site-nav__link-icon {
  visibility: hidden;
  position: absolute;
  width: 1em;
  height: 1em;
  transform: translateX(-20px) translateY(5px);
}
.site-nav__link.active {
  background-image: url("../images/icon-caret.svg");
}
.site-nav__link.active-icon {
  visibility: visible;
}

.page-nav {
  position: absolute;
  bottom: 40px;
  display: flex;
  width: 100%;
  height: 30.4px;
}
.page-nav--hidden .page-nav {
  display: none;
}
.page-nav__prev, .page-nav__next {
  text-decoration: none;
  display: inline-block;
}
.page-nav__prev .page-nav__icon, .page-nav__next .page-nav__icon {
  width: 18px;
  height: 30.4px;
  pointer-events: none;
}
.page-nav__prev.disabled, .page-nav__next.disabled {
  opacity: 0.25;
  cursor: default;
}
.page-nav__prev {
  margin-right: 10px;
}
.page-nav__prev .page-nav__icon {
  transform: rotate(180deg);
}
.page-nav__next {
  margin-left: 10px;
}

.color-swatch-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 80px;
}
.color-swatch-grid .color-swatch {
  width: 100%;
}
@media (min-width: 30em) {
  .color-swatch-grid .color-swatch {
    width: 50%;
  }
}
@media (min-width: 45em) {
  .color-swatch-grid .color-swatch {
    width: 33.3333333333%;
  }
}

.color-swatch {
  margin-bottom: 40px;
}
.color-swatch__primary {
  display: flex;
}
.color-swatch__primary .color {
  flex-shrink: 0;
  width: 50%;
  padding-bottom: 50%;
}
.color-swatch__primary .label {
  display: flex;
  flex-direction: column;
  padding-left: 14px;
}
.color-swatch__tint {
  display: flex;
}
.color-swatch__tint .color {
  width: 50%;
  height: 40px;
}
.color-swatch__tint .label {
  padding-left: 14px;
}

.color-concept-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 736px;
  margin-top: 40px;
  margin-bottom: 80px;
}
.color-concept-grid .color-concept {
  width: 100%;
  max-width: 250px;
}
@media (min-width: 60em) {
  .color-concept-grid .color-concept {
    max-width: 300px;
    width: 50%;
  }
}

.color-concept {
  display: block;
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  height: 160px;
}
.color-concept__primary {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
}
.color-concept__secondary {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 71.25%;
}
.color-concept__tertiary {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 28.75%;
}

.editor h1 {
  font-size: 6rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
}
.editor h2 {
  color: #1a1d1b;
  font-family: ivyjournal, "Lucida Bright", Georgia, -apple-system, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.467;
  font-size: 1.25rem;
}
.editor h2 a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.editor h2 a:hover {
  color: #f1c850;
  text-decoration: none;
}
@media (min-width: 48em) {
  .editor h2 {
    font-size: 1.375rem;
  }
}
@media (min-width: 60em) {
  .editor h2 {
    font-size: 1.5rem;
  }
}
@media (min-width: 64em) {
  .editor h2 {
    font-size: 1.625rem;
  }
}
@media (min-width: 80em) {
  .editor h2 {
    font-size: 1.75rem;
  }
}
@media (min-width: 120em) {
  .editor h2 {
    font-size: 1.875rem;
  }
}
.editor h1, .editor h2, .editor h3, .editor h4, .editor h5, .editor h6 {
  margin-bottom: 0;
}
.editor h3 {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.167;
}
.editor h4 {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.111;
}
.editor h5 {
  letter-spacing: 0.01em;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.25;
}
.editor h6 {
  letter-spacing: 0.0003pxem;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}
.editor h6 a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.editor h6 a:hover {
  color: #f1c850;
  text-decoration: none;
}
.editor p {
  letter-spacing: 0.0003pxem;
  color: #1a1d1b;
  font-family: forma-djr-text, Tahoma, "Trebuchet MS", "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.3px;
  margin-bottom: 1.25rem;
}
.editor p a {
  text-decoration: none;
  color: #f1c850;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.editor p a:hover {
  color: #f1c850;
  text-decoration: none;
}
.editor p a {
  color: #828c7c;
  text-decoration: underline;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.editor p a:hover {
  color: #c8ccc5;
  text-decoration: underline;
}
.editor img {
  max-width: 100%;
  display: block;
}
.editor + .content-image {
  margin-top: 50px;
}

.typesetting {
  border-top: 1px solid #c8ccc5;
  padding-top: 50px;
  margin-top: 33px;
  margin-bottom: 33px;
}
.typesetting__example {
  margin-top: 24px;
}

.website-screen + .website-screen {
  margin-top: 5.5208333333vw;
}