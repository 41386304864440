.content {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-left: 1px solid $color__warm_white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: $site_masthead_height;

  @include mq(ipad) {
    flex-direction: row;
    padding-right: 0;
  }

  @include mq(desktop_lg) {
    padding-left: 6.46vw;
  }

  &--no-content-header .content__main {
    padding-top: 40px;
    @include mq(tablet_sm) {
      padding-top: 85px;
    }
  }


  &__intro-slide {
    position: relative;
    width: 100%;
    height: calc(100vh - (#{$site_masthead_height}) );
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;


    &-headline {
      @include typo-headline();
      text-align: left;
      margin-bottom: em(23px, 96px);
    }
    &-byline {
      @include typo-body-lrg();
    }
    @include mq(ipad) {
      padding-left: 20px;
      padding-right: 40px;
    }
  }

  &__main {
    position: relative;
    max-width: 880px + 40px;
    min-width: 320px;
    flex: 3;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 0 ($site_page_nav_content_offset + $gutter__desktop * 2) 0;
    @include mq(ipad) {
      padding-left: 40px;
      padding-right: 40px;
    }
    // @include mq(desktop_md) {
    //   padding-left: 40px;
    //   padding-right: 40px;
    // }
    @include mq(desktop_lg) {
      padding-left: 0;
    }
  }



  &__notes {
    margin-left: auto;
    background-color: $color__prickly_pear_tint_3;
    padding: 38px 38px 40px 40px;
    @include mq(ipad) {
      max-width: 276px;
    }
    @include mq(desktop_md) {
      flex: 1.5;
      max-width: 396px;
    }
  }

  &--has-notes {
    padding-bottom: $gutter__desktop * 2;
    @include mq(ipad) {
      padding-bottom: 0;
    }
    .content__main {
      padding-bottom: $gutter__desktop;
      @include mq(ipad) {
        padding-bottom: ($site_page_nav_content_offset + $gutter__desktop * 2);
      }
    }
    .page-nav {
      visibility: hidden;
      @include mq(ipad) {
        visibility: visible;
      }
    }
    > .page-nav {
      visibility: visible;
      position: relative;
      bottom: auto;
      margin-top: $gutter__desktop;
      @include mq(ipad) {
        display: none;
        visibility: hidden;
      }
    }
  }
}
